import { React, useEffect, useState } from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams } from 'react-router-dom';
// import '../App.css';
// import GetProblemInfo from '../components/GetProblemInfo';
import axiosapi from "../api/axiosapi";
// import ProblemStatDescription from '../components/ProblemStatDescription';
// import useAuth from '../hooks/useAuth';
// import UserSolvingStat from '../components/UserSolvingStat';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
// import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';

const DoorProblem = ()=> {
    const {problemURL}=useParams();
    // const navigateto = useNavigate();
    // const {auth} = useAuth();
    const [problemInfo, setProblemInfo] = useState('');
    const [problemDescription, setProblemDescription] = useState([]);
    const [problemPoem, setProblemPoem] = useState([]);

    useEffect (() => {
        const getProblemInfo = async () => {
            try {
                const response = await axiosapi.get('/door/problem/', {
                    params: {
                        url: problemURL
                    },
                    withCredentials: true
                })
                response.data && setProblemInfo(response.data);
                const problemDescriptionData = response.data.problemDescription && response.data.problemDescription.split(/<br>|\|/);
                setProblemDescription(problemDescriptionData);
                const problemPoem = response.data.problemPoem && response.data.problemPoem.split("|");
                setProblemPoem(problemPoem);
            } catch (err) {
                console.log(err);
            }
        }

        getProblemInfo();
    }, [problemURL])

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
      
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
      
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
      
        return <div>{contentArray}</div>;
    };


    return(
        <div className="problem" key={problemURL}>
            {problemInfo.problemImage 
                ? <Helmet>
                    <title>티프매쓰 : 도형사고력</title>
                    <meta name="description" content="가상의 자와 컴퍼스를 사용하는 작도 기반의 온라인 사고 실험 공간입니다. 상상과 직관을 논리적 체계로 구현해 내는 사고력 강화 훈련을 통해 두뇌 활동을 촉진합니다." />
                    <meta property="og:title" content={problemInfo.problemTitle} />
                    <meta property="og:description" content={problemInfo.problemDescription} />
                    <meta property="og:url" content={'https://tiffmath.com/door/problem/'+problemURL} />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content={'https://tiffmath.com/image/'+problemInfo.problemImage} />
                </Helmet>
                : <Helmet>
                    <title>티프매쓰 : 도형사고력</title>
                    <meta name="description" content="가상의 자와 컴퍼스를 사용하는 작도 기반의 온라인 사고 실험 공간입니다. 상상과 직관을 논리적 체계로 구현해 내는 사고력 강화 훈련을 통해 두뇌 활동을 촉진합니다." />
                    <meta property="og:title" content={problemInfo.problemTitle} />
                    <meta property="og:description" content={problemInfo.problemDescription} />
                    <meta property="og:url" content={'https://tiffmath.com/door/problem/'+problemURL} />
                    <meta property="og:type" content="website" />
                </Helmet>
                // ? <Helmet>
                //     <title>{splitContent(problemInfo.problemTitle)}</title>
                //     <meta name="description" content={splitContent(problemInfo.problemDescription)} />
                //     <meta property="og:title" content={splitContent(problemInfo.problemTitle)} />
                //     <meta property="og:description" content={splitContent(problemInfo.problemDescription)} />
                //     <meta property="og:url" content={"https://tiffmath.com/door/problem/"+problemURL} />
                //     {/* <meta property="og:type" content="website" /> */}
                //     <meta property="og:image" content={"https://tiffmath.com/image/"+problemInfo.problemImage} />
                // </Helmet>
                // : <Helmet>
                //     <title>{splitContent(problemInfo.problemTitle)}</title>
                //     <meta name="description" content={splitContent(problemInfo.problemDescription)} />
                //     <meta property="og:title" content={splitContent(problemInfo.problemTitle)} />
                //     <meta property="og:description" content={splitContent(problemInfo.problemDescription)} />
                //     <meta property="og:url" content={"https://tiffmath.com/door/problem/"+problemURL} />
                //     {/* <meta property="og:type" content="website" />
                //     <meta property="og:image" content="http://www.example.com/image.jpg" /> */}
                // </Helmet>
            }

            <div className="content-fit">
                {<div style={{fontSize:24,fontWeight:900, marginTop:"20px", marginBottom:"25px"}}>
                    <div>
                        {problemInfo.problemTitle && splitContent(problemInfo.problemTitle)}
                    </div>
                </div>}

                {<div style={{marginLeft:"5px"}}>
                    {problemPoem && problemPoem.length > 2 && <div>
                        <p>수학과 시는 겉보기엔 다르지만, 내면에는 깊은 연결고리가 있습니다. 수학은 논리와 추론의 언어로 세상을 설명하고, 시는 함축과 은유의 언어로 감성을 표현합니다. 하지만 둘 다 제한된 형식 속에서 무한한 상상력을 펼친다는 공통점을 지닙니다. 수학의 간결한 공식은 시의 함축적인 언어처럼 진리를 담고 있으며, 시의 리듬과 운율은 수학의 정교한 구조처럼 아름다움을 선사합니다. 즉, 수학과 시는 각각 논리와 감성이라는 두 개의 날개로 인간의 지성과 감성을 풍요롭게 하는 예술이라 할 수 있습니다.</p>
                        <p>티프매쓰에서는 수학이 시가 되고, 시는 영감을 주고, 수학은 지성과 감성의 풍요로운 완성이 됩니다.</p>
                        <p>시를 감상하며 상상력과 직관력을 한껏 고양시켜 보세요.</p>
                    </div>}
                    <p><b>{problemPoem && problemPoem.length > 2 && problemInfo.problemPoemTitle && splitContent(problemInfo.problemPoemTitle)}</b></p>
                    {problemPoem && problemPoem.length > 2 && problemPoem.map(poem => {
                        return (
                            <div>{poem.trim().length>0 ? splitContent(poem) : <br/>}</div>
                            // <div>{poem}<br/></div>
                        )
                    })}
                </div>}

                {<div style={{marginTop:"15px", marginLeft:"5px"}}>
                    {problemPoem && problemPoem.length > 2 && <p><b>준비가 되었나요? 이제 문제를 풀어보세요.</b></p>}
                    {/* {problemInfo.problemDescription && splitContent(problemInfo.problemDescription)} */}
                    {problemDescription.length>0 && problemDescription.map(description => {
                        return (
                            <div style={{marginBottom:"7px"}}>
                                {
                                    description.includes("제목:") 
                                    ? <p style={{fontSize:18, fontWeight:700, marginBottom:"10px"}}>{description}</p>
                                    : description.trim().length>0 ? splitContent(description) : ""
                                }
                            </div>
                        )
                    })}
                </div>}
                {/* {problemInfo.problemImage && <img src={`/image/${problemInfo.problemImage}`} style={{width: 50, alignItems:'center'}} />} */}
                {problemInfo.problemType != 3 && <div style={{marginLeft:"5px"}}>
                    {problemInfo.triedUsersNum === 0
                        ? "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                        : problemInfo.successfulUserNum === 0 
                            ? `${problemInfo.triedUsersNum}명이 도전했으나 아직 문제를 풀어낸 사람이 아직 없습니다. 문제를 푸는 첫 번째 사용자가 되시기 바랍니다.`
                            : `${problemInfo.triedUsersNum}명이 도전해 ${problemInfo.successfulUserNum}명이 문제를 풀었습니다. 문제를 풀어낸 ${(problemInfo.successfulUserNum/problemInfo.triedUsersNum*100).toFixed(0)}%의 사용자와 같은 자리를 차지하세요.`
                    }
                </div>}

                {/* <p style={{marginLeft:"5px"}}>{problemInfo.publicationDate}</p> */}
            
                {problemInfo && <GeoGebraP
                    title={problemInfo.problemTitle}
                    idIn={problemInfo.problemCode}
                    customToolBarIn={problemInfo.customToolBar}
                    allowStyleBarIn={problemInfo.allowStyleBar}
                    ggbBase64In={problemInfo.problemContent}
                    problemType={problemInfo.problemType}
                    showToolBarHelpIn={problemInfo.showToolBarHelp}
                    enableLabelDragIn={problemInfo.enableLabelDrag}
                    enableShiftDragZoomIn={problemInfo.enableShiftDragZoom}
                />}
            
                {/* <div className='problemmove'>
                    {(problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 6 || problem.problemType === 7)
                        ? <button
                                className='button'
                                onClick={
                                    () => auth?.accessToken
                                        ? navigateto(`/todayproblem/my/s/list`, {state:{
                                            "todayProblemList": todayProblemListV,
                                            "problem": problem,
                                            "todayProblem": todayProblem,
                                            "useStat": useStat,
                                            "isFirst": isFirst,
                                            "isLast": isLast,
                                            "prevProblem": prevProblem,
                                            "nextProblem": nextProblem,
                                            "proID": proID
                                        }})
                                        : navigateto("/login", {state:{"from": location}}, {replace: true})
                                }
                            >
                                내 풀이보기
                            </button>
                        : null
                    }

                    <button
                        className='button'
                        onClick={
                            () => auth?.accessToken
                                ? navigateto(`/todayproblem/image/list/`, {state:{
                                    "todayProblemList": todayProblemListV,
                                    "todayProblem": todayProblem,
                                    "problem":problem
                                }})
                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                        }
                    >
                        그림보기
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default DoorProblem;
