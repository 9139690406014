import { React, useEffect, useState } from 'react';
// import GeoGebraP from '../components/GeoGebraP';
import { useParams, useNavigate } from 'react-router-dom';
// import '../App.css';
// import GetProblemInfo from '../components/GetProblemInfo';
import axiosapi from "../api/axiosapi";
// import ProblemStatDescription from '../components/ProblemStatDescription';
// import useAuth from '../hooks/useAuth';
// import UserSolvingStat from '../components/UserSolvingStat';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
// import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';


const DoorBrainUp = ()=> {
    const {brainUpDoorURL}=useParams();
    const navigateto = useNavigate();
    // const {todayProblemList, todayProblem} = useLocation().state;
    // const location = useLocation();
    // const {auth} = useAuth();
    const [brainUpInfo, setBrainUpInfo] = useState('');
    const [brainUpStory, setBrainUpStory] = useState([]);

    useEffect (() => {
        const getBrainUpInfo = async () => {
            try {
                const response = await axiosapi.get('/door/brainup/', {
                    params: {
                        url: brainUpDoorURL
                    },
                    withCredentials: true
                })
                response.data && setBrainUpInfo(response.data);
                const brainUpStoryD = response.data && response.data.brainUpStory && response.data.brainUpStory.split("|");
                setBrainUpStory(brainUpStoryD);
            } catch (err) {
                console.log(err);
            }
        }

        getBrainUpInfo();
    }, [brainUpDoorURL])

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
      
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
      
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
      
        return <div>{contentArray}</div>;
    };

    return(
        <div className="App-full-container" key={brainUpDoorURL}>
            <Helmet>
                <title>{brainUpInfo.brainUpTitle && brainUpInfo.brainUpTitle}</title>
                <meta name="description" content={brainUpInfo.brainUpDescription  && brainUpInfo.brainUpDescription} />
                <meta property="og:title" content={brainUpInfo.brainUpTitle && brainUpInfo.brainUpTitle} />
                <meta property="og:description" content={brainUpInfo.brainUpDescription  && brainUpInfo.brainUpDescription} />
                <meta property="og:url" content={"https://tiffmath.com/door/brainup/"+brainUpDoorURL} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={"https://tiffmath.com/image/"+brainUpInfo.imageFileName} />
            </Helmet>
            <div className="position-center-box-2-1">
                <h5 style={{fontWeight:"bolder", color:"orange" ,marginTop:"22px", marginBottom:"15px"}}>{brainUpInfo.brainUpTitle}</h5>

                {<div style={{marginLeft:"5px"}}>
                    {brainUpInfo.brainUpDescription  && splitContent(brainUpInfo.brainUpDescription)}

                </div>}
                
                <div style={{marginLeft:"5px"}}>
                    {brainUpInfo.brainUpStory && <button 
                        className='button' 
                        onClick={() => navigateto(`/improvemathability/${brainUpInfo.categoryCode}`, {state:{
                            "categoryCode": brainUpInfo.categoryCode,
                            "problemCode": brainUpInfo.firstProblemCode,
                            "improveAbilityTitle": brainUpInfo.brainUpTitle,
                            "improveAbilityType": brainUpInfo.brainUpType
                        }})}
                    >
                        바로 두뇌 활성화 문제 풀어보기
                    </button>}
                    {brainUpInfo.brainUpStory && <div>
                        <br/>
                        <br/>
                    </div>}

                    {/* <p style={{marginLeft:"5px"}}>{brainUpInfo.publicationDate}</p> */}

                    {brainUpInfo.brainUpStory && brainUpInfo.brainUpStoryTitle && <b><p>{splitContent(brainUpInfo.brainUpStoryTitle)}</p></b>}

                    {brainUpInfo.imageFileName && <img src={`/image/${brainUpInfo.imageFileName}`} style={{width:'95%', marginBottom:'10px'}} />}
                    <br/>                
                    {brainUpInfo.brainUpStory && brainUpStory.map(item => (<div><p>{splitContent(item)}</p></div>))}

                    <button 
                        className='button' 
                        onClick={() => navigateto(`/improvemathability/${brainUpInfo.categoryCode}`, {state:{
                            "categoryCode": brainUpInfo.categoryCode,
                            "problemCode": brainUpInfo.firstProblemCode,
                            "improveAbilityTitle": brainUpInfo.brainUpTitle,
                            "improveAbilityType": brainUpInfo.brainUpType
                        }})}
                    >
                        두뇌 활성화 문제 풀어보기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DoorBrainUp;
